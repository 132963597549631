import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Tooltip,
    Legend,
    LayoutPosition,
} from 'chart.js';
import { useQuery } from '@apollo/client';

import { onCompletedHandler, onErrorHandler } from '@/services/apollo';
import Title from '@/components/commons/page-elements/Title';

import { FETCH_SENT_LAST7, FETCH_RECEIVED_LAST7, FETCH_LAST_6_MONTHS } from './Actions';
import TopContactsLast30 from './TopContactsLast30';
import styles from './Dashboard.module.css';
import ColumnChart from './ColumnChart';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Tooltip,
    Legend,
);

const titleFont = {
    family: '\'Inter\', \'Helvetica\', \'Arial\', sans-serif',
    weight: '400',
    size: 14,
};

const legendFont = {
    family: '\'Inter\', \'Helvetica\', \'Arial\', sans-serif',
    weight: '400',
};

const legendConfig = {
    position: 'bottom' as LayoutPosition,
    display: true,
    labels: {
        usePointStyle: true,
        font: legendFont,
    },
};

// here, colors need to be hardcoded because apexGraphics doesn´t search for them in :root
const noonBlue = '#47BDD8';
const cleanSlate = '#434F54';
const red = '#E63946';
const bloodRed = '#A62D38';
const orange = '#F8B145';
const yellow = '#FBDE99';
const teal = '#1091AE';
const green = '#5ACD7A';
const gray = '#ACB9BE';
const lightGray = '#EEF2F3';

function Dashboard() {
    const [isErrorSent, setIsErrorSent] = useState(false);
    const [isErrorReceived, setIsErrorReceived] = useState(false);
    const [isErrorLast6, setIsErrorLast6] = useState(false);

    const [messagesSentLast7, setMessagesSentLast7] = useState({
        labels: [],
        values: [],
    });
    const [messagesReceivedLast7, setMessagesReceivedLast7] = useState({
        labels: [],
        values: [],
    });

    const [messagesLast6, setMessagesLast6] = useState({
        sent: {
            labels: [],
            values: [],
        },
        received: {
            labels: [],
            values: [],
        },
    });

    const { loading: loadingLast6 } = useQuery(FETCH_LAST_6_MONTHS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onError: onErrorHandler(setIsErrorLast6),
        onCompleted: onCompletedHandler(setIsErrorLast6, (data) => {
            setMessagesLast6({
                sent: data.messagesSentLast6Months,
                received: data.messagesReceivedLast6Months,
            });
        }),
    });

    const { loading: loadingSent } = useQuery(FETCH_SENT_LAST7, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onError: onErrorHandler(setIsErrorSent),
        onCompleted: onCompletedHandler(setIsErrorSent, (data) => {
            setMessagesSentLast7(data.messagesSentLast7Days);
        }),
    });

    const { loading: loadingReceived } = useQuery(FETCH_RECEIVED_LAST7, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onError: onErrorHandler(setIsErrorReceived),
        onCompleted: onCompletedHandler(setIsErrorReceived, (data) => {
            setMessagesReceivedLast7(data.messagesReceivedLast7Days);
        }),
    });

    const baseOptions = {
        chart: {
            fontFamily: 'instrument, sans-serif',
            foreColor: 'var(--color-cleanSlate)',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 8,
                borderRadiusApplication: 'top',
                borderRadiusWhenStacked: 'last',
                columnWidth: '50%',
            },
        },
        xaxis: {
            axisBorder: { show: false },
            axisTicks: { show: false },
        },
        grid: { show: false },
        dataLabels: { enabled: false },
        legend: { show: false },
        tooltip: { enabled: false },
    };

    const charts = [
        {
            key: 'outgoing-emails',
            options: {
                ...baseOptions,
                chart: {
                    ...baseOptions.chart,
                    id: 'outgoing-emails',
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: noonBlue,
                    },
                },
                xaxis: {
                    ...baseOptions.xaxis,
                    categories: messagesSentLast7?.labels,
                },
            },
            series: [
                {
                    name: 'Outgoing emails',
                    data: messagesSentLast7?.values,
                },
            ],
            containerStyles: '',
            title: 'Outgoing emails',
            loading: loadingSent,
            isError: isErrorSent,
        },
        {
            key: 'incoming-emails',
            options: {
                ...baseOptions,
                chart: {
                    ...baseOptions.chart,
                    id: 'incoming-emails',
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: noonBlue,
                    },
                },
                xaxis: {
                    ...baseOptions.xaxis,
                    categories: messagesReceivedLast7?.labels,
                },
            },
            series: [
                {
                    name: 'Incoming emails',
                    data: messagesReceivedLast7?.values,
                },
            ],
            containerStyles: '',
            title: 'Incoming emails',
            loading: loadingReceived,
            isError: isErrorReceived,
        },
        {
            key: 'total-volume',
            options: {
                ...baseOptions,
                chart: {
                    ...baseOptions.chart,
                    stacked: 'true',
                    id: 'total-volume',
                },
                colors: [noonBlue, cleanSlate],
                xaxis: {
                    ...baseOptions.xaxis,
                    categories: messagesLast6?.sent.labels,
                },
            },
            series: [
                {
                    name: 'Sent',
                    data: messagesLast6?.sent.values,
                },
                {
                    name: 'Received',
                    data: messagesLast6?.received.values,
                },
            ],
            containerStyles: 'col-span-full',
            title: 'Total volume',
            loading: loadingLast6,
            isError: isErrorLast6,
        },
    ];

    return (
        <div className="">
            <div className="pr-6 mb-10">
                <Title
                    title="Insights"
                    subtitle="Understanding who you are connecting with and how can help
                you take back your time without missing out or losing touch."
                    bold
                />
            </div>
            <div className={styles.dashGraphContainer}>
                {
                    charts.map(({
                        key,
                        containerStyles,
                        options,
                        series,
                        title,
                        loading,
                        isError,
                    }) => (
                        <ColumnChart
                            key={key}
                            containerStyles={containerStyles}
                            options={options}
                            series={series}
                            title={title}
                            loading={loading}
                            isError={isError}
                        />
                    ))
                }
            </div>

            <h3 className={styles.dashHeader}>Contacts</h3>

            <div className={styles.dashGraphContainer}>
                <TopContactsLast30
                    bgColors={[
                        noonBlue,
                        cleanSlate,
                        red,
                        bloodRed,
                        orange,
                        yellow,
                        teal,
                        green,
                        gray,
                        lightGray,
                    ]}
                    legendConfig={legendConfig}
                    titleFont={titleFont}
                />

                {/*
                <div className={`${styles.dashGraph}`}>
                    <Bar
                        data={{
                            labels: TOP_CONTACTS,
                            datasets: [
                                {
                                    label: 'Outgoing',
                                    backgroundColor: [SECONDARY_LIGHT],
                                    borderColor: 'rgba(0,0,0,1)',
                                    borderWidth: 0,
                                    data: [80, 65, 48, 75, 53, 37],
                                },
                                {
                                    label: 'Incoming',
                                    backgroundColor: [PRIMARY_DEFAULT],
                                    borderColor: 'rgba(0,0,0,1)',
                                    borderWidth: 0,
                                    data: [51, 42, 67, 53, 58, 55],
                                },
                            ],
                        }}
                        options={{
                            indexAxis: 'x',
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    ticks: {
                                        callback: percentTick,
                                    },
                                },
                            },
                            plugins: {
                                legend: legendConfig,
                                title: {
                                    display: true,
                                    text: 'Response Rates',
                                    font: titleFont,
                                },
                            },
                        }}
                    />
                </div>

                <div className={`${styles.dashGraph}`}>
                    <Bar
                        data={{
                            labels: TOP_CONTACTS,
                            datasets: [
                                {
                                    label: 'Outgoing',
                                    backgroundColor: SECONDARY_LIGHT,
                                    borderColor: 'rgba(0,0,0,1)',
                                    borderWidth: 0,
                                    data: [1.1, 0.8, 1.9, 1.4, 0.2, 2.7],
                                },
                                {
                                    label: 'Incoming',
                                    backgroundColor: PRIMARY_DEFAULT,
                                    borderColor: 'rgba(0,0,0,1)',
                                    borderWidth: 0,
                                    data: [1.3, 1.7, 2.4, 1.7, 6.5, 4.9],
                                },
                            ],
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    ticks: {
                                        callback: hoursTick,
                                    },
                                },
                            },
                            plugins: {
                                legend: legendConfig,
                                title: {
                                    display: true,
                                    text: 'Response Time',
                                    font: titleFont,
                                },
                            },
                        }}
                    />
                </div>

                <div className={`${styles.dashGraph} ${styles.dashGraphLarge}`}>
                    <Bar
                        data={{
                            labels: LOSING_TOUCH,
                            datasets: [
                                {
                                    label: 'Normal Email Frequency',
                                    backgroundColor: SECONDARY_LIGHT,
                                    borderColor: 'rgba(0,0,0,1)',
                                    borderWidth: 0,
                                    data: [30, 20, 8, 5, 17, 13],
                                },
                                {
                                    label: 'Time Since Last Email',
                                    backgroundColor: TERTIARY_LIGHT,
                                    borderColor: 'rgba(0,0,0,1)',
                                    borderWidth: 0,
                                    data: [61, 110, 34, 34, 43, 56],
                                },
                            ],
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    ticks: {
                                        callback: daysTick,
                                    },
                                },
                            },
                            plugins: {
                                legend: legendConfig,
                                title: {
                                    display: true,
                                    text: 'Losing Touch',
                                    font: titleFont,
                                },
                            },
                        }}
                    />
                </div>
                */}
            </div>
        </div>
    );
}

export default Dashboard;
