import React from 'react';

export enum HeadingSizes {
    H1,
    H2,
    H3,
}

interface Props {
    readonly children: any;
    readonly size?: HeadingSizes;
    readonly externalStyles?: string;
    readonly bold?: boolean;
}

/**
 * Component used to add consistent styling for Headings throughout
 * the app, also supports externalStyles if tweaks are needed.
 */
function Heading({
    children,
    size = HeadingSizes.H3,
    externalStyles = '',
    bold = false,
}: Props) {
    // default to h3
    const baseStyles = 'text-cleanSlate';

    const fontType = bold ? 'font-bold' : 'font-medium';

    let className = `${baseStyles} text-xl ${fontType} ${externalStyles}`;
    let heading = <h3 className={className}>{children}</h3>;

    if (size === HeadingSizes.H1) {
        // h1 component and styles
        className = `${baseStyles} text-2xl font-bold ${externalStyles}`;
        heading = <h1 className={className}>{children}</h1>;
    } else if (size === HeadingSizes.H2) {
        // h2 component and styles
        className = `${baseStyles} text-2xl text-cleanSlate font-bold font-jazmin font-medium ${externalStyles}`;
        heading = <h2 className={className}>{children}</h2>;
    }
    return heading;
}

export default Heading;
