import React, { ReactElement } from 'react';

import Heading, { HeadingSizes } from './Heading';

interface Props {
    readonly title?: string;
    readonly subtitle?: string;
    // reduces the sizing
    readonly small?: boolean;
    readonly bold?: boolean;
    readonly children?: ReactElement | Array<ReactElement>
    readonly externalStyles?: string;
    readonly breakpointFull?: string;
}

function Title({
    small = false,
    title = null,
    subtitle = null,
    bold = false,
    children = undefined,
    externalStyles = '',
    breakpointFull = 'sm',
}: Props) {
    return (
        <div className={`w-full ${externalStyles}`}>
            {small ? (
                <Heading bold={bold} externalStyles="my-1">
                    {title}
                </Heading>
            ) : (
                <Heading bold={bold} size={HeadingSizes.H2} externalStyles="">
                    {title}
                </Heading>
            )}
            <div className="flex flex-col gap-4 w-full lg:flex-row lg:justify-between lg:items-center">
                {subtitle && <p className="flex-col pr-0 leading-normal mb-0">{subtitle}</p>}
                {children && <div className={`flex ${breakpointFull}:flex w-full justify-center ${breakpointFull}:justify-end`}>{children}</div>}
            </div>
        </div>
    );
}

export default Title;
