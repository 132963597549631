import React, { JSX } from 'react';

import styles from './Page.module.css';

interface Props {
    readonly display?: boolean,
    readonly headline?: string,
    readonly description?: string,
    readonly headerControls?: JSX.Element | JSX.Element[],
    readonly children?: JSX.Element | JSX.Element[],
}

function Page({
    display = true,
    headline = '',
    description = '',
    headerControls = [],
    children = [],
} : Props) {
    return (
        display
        && (
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    <div className="flex-1 ml-correct">
                        <h2 className={styles.headline}>
                            {headline}
                        </h2>
                        {description
                            && (
                                <div>
                                    <h3 className={styles.pageDescription}>
                                        {description}
                                    </h3>
                                </div>
                            )}
                    </div>
                    <div className={styles.headerControls}>
                        {headerControls}
                    </div>
                </div>
                <div>
                    {children}
                </div>
            </div>
        )
    );
}

export default Page;
