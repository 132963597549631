import React from 'react';

interface Props {
    readonly children: React.JSX.Element | React.JSX.Element[];
    readonly externalStyles?: string;
    readonly padding?: Array<number>;
    readonly rounded?: string;
    readonly backgroundColor?: string;
}

function SmartContainer({
    children,
    externalStyles = '',
    backgroundColor = 'cirrusWhite',
    padding = [],
    rounded = null,
}: Props) {
    const paddingDefault = `pt-${padding[0] || 0} pr-${padding[1] || 0} pb-${padding[2] || 0} pl-${padding[3] || 0}`;
    const className = `bg-${backgroundColor} ${rounded ? `rounded-${rounded}` : ''} ${paddingDefault} ${externalStyles}`;

    return (
        <div className={className}>
            {children}
        </div>
    );
}

export default SmartContainer;
