/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useLazyQuery, useQuery } from '@apollo/client';

import useURL from '@/hooks/use_url';
import routes from '@/services/routes';
import { setSentryUserScope } from '@/services/sentry';
import {
    isOnboardingCompleted, isOnboardingNow, getOnboardingUrl,
} from '@/components/onboarding/Shared';
import Loader from '@/components/commons/loader';
import { onCompletedHandler, onErrorHandler } from '@/services/apollo';
import NetworkError from '@/components/commons/network_error';

import {
    initializedAtom, userAtom, serverDownErrorAtom, UserContent,
} from './State';
import { SIGNED_IN } from './Actions';

const withAuth = (Component) => {
    function Auth(props) {
        const router = useRouter();
        const url = useURL();
        const loginDisabled = url.error === 'login_disabled';
        const localPath = router ? router.pathname : null;
        const [initialized, setInitialized] = useRecoilState(initializedAtom);
        const [user, setUser] = useRecoilState(userAtom);
        const [serverDownError, setServerDownError] = useRecoilState(serverDownErrorAtom);
        // N.B. the `hasRedirected` is because we're seeing some situations where we wind up calling
        // router.push multiple times in one page view and that's baaaad.  Not sure what piece of
        // state is changing to result in that, but this is a workaround.
        const [hasRedirected, setHasRedirected] = useState(false);
        const [notOnboarded, setNotOnboarded] = useState(false);
        const [atOnboardingPath, setAtOnboardingPath] = useState(false);

        const isLoading = !loginDisabled
            && (!initialized || (notOnboarded && !atOnboardingPath) || !user);

        useEffect(() => {
            if (router) {
                const isOnboardingPath = isOnboardingNow(router);
                if (user?.id) {
                    setNotOnboarded(!isOnboardingCompleted(user));
                }
                setAtOnboardingPath(isOnboardingPath);
            }
        }, [router, user]);

        const errCallback = (_err) => {
            setServerDownError(true);
        };

        const [signedIn] = useLazyQuery(SIGNED_IN, {
            onError: onErrorHandler(errCallback),
            onCompleted: onCompletedHandler(errCallback, (data) => {
                if (data) {
                    const userId = data.signedIn;
                    setUser(data.user);
                    setInitialized(true);

                    if (userId) {
                        setSentryUserScope(userId);
                    } else {
                        return;
                    }

                    // check if we need to redirect to Getting Started
                    const newUser: UserContent = data.user;
                    // user hasn't completed onboarding
                    const stillOnboarding = (
                        !newUser.hasCreatedAgendas
                        || !newUser.hasCreatedAlerts
                        || !newUser.hasCreatedBio
                        || !newUser.hasCreatedFolders
                    );
                    // user set to remind onboarding later
                    const remindLater = new Date(newUser.setupReminderAt) > new Date();
                    // if user came from login and didn't complete onboarding and doesn't
                    // have a reminder for later set up, take them to Getting started
                    if (url.login && stillOnboarding && !remindLater) {
                        router.push(routes.MY_SIXTY);
                    }
                }
            }),
        });

        // If we're not initialized, we want to give the executing fetchUser query time
        // to finish, rather than redirect to the login page immediately.  We could
        // render a placeholder here, but that may not allow for the best user
        // experience.  Instead, we render children but expect any child making use of
        // auth to check and do something sane when not yet initialized.
        useEffect(() => {
            if (loginDisabled) {
                return;
            }

            if (hasRedirected) {
                return;
            }

            if (!initialized) {
                signedIn();
                return;
            }

            if (router && initialized && !serverDownError) {
                if (user && notOnboarded && !atOnboardingPath) {
                    router.push(getOnboardingUrl(user));
                    setHasRedirected(true);
                }

                if (!user) {
                    if (!localPath || localPath === routes.LOGIN) {
                        router.push(routes.LOGIN);
                    } else {
                        router.push(routes.LOGIN_AND_RETURN_TO(localPath));
                    }
                    setHasRedirected(true);
                }
            }
        }, [initialized, serverDownError, router, signedIn, user, notOnboarded, atOnboardingPath,
            hasRedirected, setHasRedirected, loginDisabled, localPath]);

        return (
            <Loader
                isLoading={isLoading}
            >
                {(loginDisabled && (
                    <div className="text-center">
                        This account is either deleted, or has been disabled by customer support.
                    </div>
                )) || (serverDownError && (
                    <NetworkError />
                )) || (
                    <Component {...props} user={user} setUserRecoil={setUser} />
                )}
            </Loader>
        );
    }

    // Copy getInitial props so it will run as well
    if (Component.getInitialProps) {
        Auth.getInitialProps = Component.getInitialProps;
    }

    Auth.getLayout = Component.getLayout;

    return Auth;
};

const withOptionalAuth = (Component) => {
    function OptionalAuth(props) {
        const setInitialized = useSetRecoilState(initializedAtom);
        const [user, setUser] = useRecoilState(userAtom);
        const [serverDownError, setServerDownError] = useRecoilState(serverDownErrorAtom);

        const errCallback = (_err) => {
            setServerDownError(true);
        };

        const { loading } = useQuery(SIGNED_IN, {
            onError: onErrorHandler(errCallback),
            onCompleted: onCompletedHandler(errCallback, (data) => {
                if (data) {
                    const userId = data.signedIn;
                    setUser(data.user);
                    setInitialized(true);

                    if (userId) {
                        setSentryUserScope(userId);
                    }
                }
            }),
        });

        return (
            <Loader isLoading={loading}>
                {(serverDownError && (
                    <NetworkError />
                )) || (
                    <Component {...props} user={user} setUserRecoil={setUser} />
                )}
            </Loader>
        );
    }

    if (Component.getInitialProps) {
        OptionalAuth.getInitialProps = Component.getInitialProps;
    }

    OptionalAuth.getLayout = Component.getLayout;

    return OptionalAuth;
};

export { withAuth, withOptionalAuth };
