import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Doughnut } from 'react-chartjs-2';

import Loader from '@/components/commons/loader';
import { onCompletedHandler, onErrorHandler } from '@/services/apollo';

import { FETCH_TOP_INBOUND_CONTACTS_LAST30 } from './Actions';
import styles from './Dashboard.module.css';

function TopContactsLast30({ bgColors, legendConfig, titleFont }) {
    const [isError, setIsError] = useState(false);
    const [topInboundContactsLast30, setTopInboundContactsLast30] = useState({
        labels: [],
        values: [],
    });

    const { loading } = useQuery(FETCH_TOP_INBOUND_CONTACTS_LAST30, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onError: onErrorHandler(setIsError),
        onCompleted: onCompletedHandler(setIsError, (data) => {
            setTopInboundContactsLast30(data.topInboundContactsLast30Days);
        }),
    });

    return (
        <div className={`${styles.dashGraph} ${styles.dashGraphLarge}`}>
            <Loader isLoading={loading} isError={isError}>
                <Doughnut
                    data={{
                        labels: topInboundContactsLast30.labels,
                        datasets: [
                            {
                                label: 'Messages (Total)',
                                data: topInboundContactsLast30.values,
                                backgroundColor: bgColors,
                                borderColor: ['white'],
                                borderWidth: 4,
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: legendConfig,
                            title: {
                                display: true,
                                text: 'Top Contacts Messages',
                                font: titleFont,
                            },
                        },
                    }}
                />
            </Loader>
        </div>
    );
}

export default TopContactsLast30;
