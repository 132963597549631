import { gql } from '@apollo/client';

import { COMMON_BRIEF_FIELDS } from '@/types/globals';

export const FETCH_SENT_LAST7 = gql`
    query MessagesSentLast7 {
        messagesSentLast7Days {
            labels
            values
        }
    }
`;

export const FETCH_RECEIVED_LAST7 = gql`
    query MessagesReceivedLast7 {
        messagesReceivedLast7Days {
            labels
            values
        }
    }
`;

export const FETCH_TOP_INBOUND_CONTACTS_LAST30 = gql`
    query TopInboundContactsLast30 {
        topInboundContactsLast30Days {
            labels
            values
        }
    }
`;

export const FETCH_LAST_6_MONTHS = gql`
    query MessagesLast6Months {
        messagesSentLast6Months {
            labels
            values
        }
        messagesReceivedLast6Months {
            labels
            values
        }
    }
`;

export const FETCH_BRIEF = gql`
    query Brief($key:String,$allowRandom:Boolean!) {
        brief(key:$key,allowRandom:$allowRandom) {
            ${COMMON_BRIEF_FIELDS}
        }
    }
`;

export const CREATE_BIO_REFINEMENT = gql`
    mutation CreateBioRefinement($input: CreateBioRefinementInput!) {
        createBioRefinement(input: $input) {
            bioRefinement {
                id
                bio
                bioCandidate {
                    id
                    bio
                    status
                }
                input
                length
                perspective
                status
                temperature
                tone
            }
            errors {
                message
                path
            }
        }
    }
`;

export const FETCH_BIO_REFINEMENT = gql`
    query GetBioRefinement($id: ID!) {
        user {
            bioRefinements(id: $id) {
                id
                bio
                bioCandidate {
                    id
                    bio
                    status
                }
                input
                length
                perspective
                status
                tone
                temperature
                createdAt
            }
        }
    }
`;
