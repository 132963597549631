import React from 'react';

import ExclamationMark from '@/components/commons/icons/ExclamationMark';
import SmartContainer from '@/components/commons/smart_container';

export enum WarningCardMode {
    INFO = 'info',
    WARNING = 'warning',
}

interface Props {
    readonly message?: string;
    readonly mode?: WarningCardMode;
    readonly bkgColor?: string;
    readonly margin?: string;
    readonly padding?: any;
    readonly fontColor?: string;
}

const colorSets = {
    [WarningCardMode.INFO]: {
        bkgColor: 'progressBarBlue',
        fontColor: 'black',
    },
    [WarningCardMode.WARNING]: {
        bkgColor: 'solidRed',
        fontColor: 'black',
    },
};

export default function WarningCard({
    message = '',
    mode = WarningCardMode.WARNING,
    margin = 'my-2',
    padding = [4, 4, 4, 4],
}: Props) {
    const colors = colorSets[mode];

    // TODO: Hoist this pale pink (#ECCDD0) to tailwind config...
    // TODO: Verify that icon and text always have the same color!
    // TODO: Make a storybook, and parameterize the color by _kind_ not by _color name_.
    const warningInfo = {
        padding,
        rounded: 'lg',
        backgroundColor: colors.bkgColor,
        externalStyles: `bg-opacity-20 ${margin} flex items-center`,
        children:
    <>
        <ExclamationMark className={`w-5 h-5 mr-2 rounded-full text-${colors.bkgColor}`} />
        <p className={`text-${colors.fontColor}`}>{message}</p>
    </>,
    };

    return (
        <SmartContainer {...warningInfo} />
    );
}
