import React, { ReactNode } from 'react';

export enum ContainerTypes {
    TAN,
    WHITE,
}

interface Props {
    readonly children: ReactNode;
    readonly externalStyles?: string;
    readonly type?: ContainerTypes;
}

export default function Container({
    children,
    externalStyles = '',
    type = ContainerTypes.TAN,
}: Props) {
    // TODO(tom) if external styles so that we can define a consistent
    // margin always
    let classNames = '';
    if (type === ContainerTypes.TAN) {
        classNames = `bg-lightGray shadow-sm rounded-md border border-lightGray py-3 px-5 ${externalStyles}`;
    } else if (type === ContainerTypes.WHITE) {
        classNames = `bg-cirrusWhite shadow-md rounded-md border border-lightGray p-5 ${externalStyles}`;
    }

    return (
        <div className={classNames}>
            {children}
        </div>
    );
}
