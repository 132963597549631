import React, { ReactNode } from 'react';

import WarningCard from '@/components/commons/warning_card';

import styles from './Loader.module.css';

interface Props {
    readonly children?: ReactNode;
    readonly isLoading: boolean;
    readonly isError?: boolean;
    readonly loaderSizeClass?: string;
}

export default function Loader({
    children = null,
    isLoading,
    isError = false,
    loaderSizeClass = 'h-20 w-20',
}: Props) {
    if (isError) {
        // N.B. The `is-error` class below is for tests!
        return (
            <div className="flex py-10 is-error">
                <div className="w-full">
                    <WarningCard message="Whoops!  Something went wrong." />
                </div>
            </div>
        );
    }

    // N.B. The `is-loading` class below is for tests!
    return (
        isLoading ? (
            <div className="flex py-10 is-loading">
                <div className="m-auto">
                    <div className={`${styles.loader} ease-linear rounded-full border-8 ${loaderSizeClass}`} />
                </div>
            </div>
        ) : (
            children
        )
    );
}
