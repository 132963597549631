import React from 'react';
import dynamic from 'next/dynamic';

import Container, { ContainerTypes } from '@/components/commons/page-elements/Container';
import Loader from '@/components/commons/loader';

const ApexChart = dynamic(() => import('react-apexcharts'), { ssr: false });

export default function ColumnChart({
    containerStyles, options, series, title, loading, isError,
}) {
    return (
        <Container externalStyles={containerStyles} type={ContainerTypes.WHITE}>
            <Loader isLoading={loading} isError={isError}>
                <p className="text-lg font-bold text-cleanSlate mb-6">{title}</p>
                <div className="-m-4 -mr-2">
                    <ApexChart
                        options={options}
                        series={series}
                        type="bar"
                    />
                </div>

            </Loader>
        </Container>
    );
}
